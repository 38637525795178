
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'ShowcaseEntry',
        props: {
            githubLink: String,
            title: String,
            tags: Array
        }
    })
