<template>
    <div class="container text-light">
        <Header />
        <div class="row site-body body-headernav-border">
            <SiteBody />
        </div>
        <Footer />
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import Header from './components/Header.vue';
    import SiteBody from './components/SiteBody.vue';
    import Footer from './components/Footer.vue';
    import './css/styles.css';

    export default defineComponent({
        name: 'MainSite',
        components: {
            Header,
            SiteBody,
            Footer
        }
    });
</script>