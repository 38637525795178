
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'Header',
        data() {
            return {
                path: window.location.pathname
            }
        }
    });
